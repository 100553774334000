<template>
  <div ref="menuActivator" class="relative">
    <icon-base
      v-on-click-outside="closeMenu"
      role="menuActivator"
      icon="vertical-dots"
      height="24"
      width="24"
      data-cy="menu-button"
      @click.prevent.stop="toggleMenu"
    />
    <teleport to="#menus">
      <ul
        v-show="showMenu"
        :style="`top: ${top - 25}px; left: ${left + 15}px`"
        class="offer-dropdown-menu absolute transform -translate-x-full z-10 flex flex-col items-start justify-start bg-white shadow-md w-max text-left rounded"
        :class="{
          'translate-y-8': !openOnTop,
          '-translate-y-full': openOnTop
        }"
      >
        <li class="w-full hover:bg-blue-100">
          <button
            class="block w-full py-2 px-4 text-left"
            @click.prevent.stop="toggleOfferModal"
          >
            {{ $t("COMMON.EDIT") }}
          </button>
        </li>
        <li v-if="!isClosing" class="w-full hover:bg-blue-100">
          <button
            class="block w-full py-2 px-4 text-left"
            @click.prevent.stop="duplicateOffer"
          >
            {{ $t("COMMON.DUPLICATE") }}
          </button>
        </li>
        <li class="w-full hover:bg-blue-100">
          <button
            class="block w-full py-2 px-4 text-left"
            :disabled="isWebhookSending"
            @click.prevent.stop="sendWebhook"
          >
            {{ $t("COMMON.SEND") }} {{ $t("ORGANIZATION.API_CONFIG.WEBHOOKS") }}
          </button>
        </li>
        <li class="cursor-pointer">
          <status-menu :offer="model" />
        </li>
        <li class="w-full hover:bg-blue-100 text-red-400">
          <delete-offer-modal
            v-if="showDeleteOfferModal"
            :selected-offers="[model.id]"
            :offers="[model]"
            :close="() => (showDeleteOfferModal = false)"
          />
          <button
            class="block w-full py-2 px-4 text-left"
            @click.prevent.stop="showDeleteOfferModal = true"
          >
            {{ $t("COMMON.DELETE") }}
          </button>
        </li>
      </ul>
    </teleport>
    <offer-modal
      v-if="showOfferModal"
      :offer-details="[model.details]"
      edit-mode
      :is-closing="isClosing"
      @close="showOfferModal = false"
    />
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import DeleteOfferModal from "@/views/deals/modals/DeleteOfferModal.vue";
import { useStore } from "vuex";
import { usePromiseWrapper } from "@/hooks/common";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import { useDropdownMenu } from "@/hooks/menus";
import type { IOffer } from "@/models/funders";
import OfferModal from "@/views/deals/components/OfferModal.vue";
import applicationsApiService from "@/services/modules/applications";

const props = defineProps({
  model: {
    type: Object as PropType<IOffer>,
    required: true
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  isClosing: {
    type: Boolean,
    default: false
  }
});

const { dispatch } = useStore();
const { showMessage } = useNotification();
const { t } = useI18n();

const openOnTop = ref(false);
const showDeleteOfferModal = ref(false);
const showOfferModal = ref(false);
const scrollable = "dealsWrapper";

const { showMenu, toggleMenu, closeMenu, top, left, menuActivator } =
  useDropdownMenu(scrollable);

const calculateMenuPosition = () => {
  const windowInnerHeight = window.innerHeight;
  const menuActivatorBottom =
    menuActivator.value?.getBoundingClientRect()?.bottom || 0;

  if (windowInnerHeight - menuActivatorBottom < 250) {
    openOnTop.value = true;
    return;
  }
  openOnTop.value = false;
};

onMounted(() => {
  calculateMenuPosition();
  document
    .getElementById(scrollable)
    ?.addEventListener("scroll", calculateMenuPosition);
});

onBeforeUnmount(() => {
  document
    .getElementById(scrollable)
    ?.removeEventListener("scroll", calculateMenuPosition);
});

const { fetchWrapper: sendWebhook, loading: isWebhookSending } =
  usePromiseWrapper(async () => {
    await applicationsApiService.sendOfferAvailableWebhook(props.model.id);
    // override the axios client skipping offers url for toast for this request
    showMessage(t("HTTP.RESPONSE.CREATE"), "success");
  });

const duplicateOffer = async (): Promise<void> => {
  await dispatch("applications/duplicateOffer", {
    id: props.model.id,
    ignoreSnapshot: false
  });

  closeMenu({
    afterClose: () => {
      return;
    }
  });
};

const toggleOfferModal = (): void => {
  closeMenu({
    afterClose: () => {
      showOfferModal.value = true;
    }
  });
};
</script>
